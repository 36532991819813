import { render, staticRenderFns } from "./caseInfo2.vue?vue&type=template&id=388d3668&scoped=true&"
import script from "./caseInfo2.vue?vue&type=script&lang=js&"
export * from "./caseInfo2.vue?vue&type=script&lang=js&"
import style0 from "./caseInfo2.vue?vue&type=style&index=0&id=388d3668&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "388d3668",
  null
  
)

export default component.exports